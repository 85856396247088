<template>
  <b-card class="card">
    <div
      v-if="isLoading"
      class="text-center"
    >
      <b-spinner
        class="spinner"
        variant="primary"
        label="Verifying"
      />
      <h3 class="mt-2">
        ...Verifying Email
      </h3>
    </div>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import usersService from "@/services/usersService";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import {
  BSpinner,
  BCard
} from 'bootstrap-vue';
export default {
  components: {
    BSpinner,
    BCard
  },
  data() {
    return {
      isLoading: true,
    };
  },

  computed: {
    ...mapGetters("app", ["currentProgram"]),
  },

  mounted() {
    this.onSubmit();
  },

  methods: {
    async onSubmit() {
      if (!this.currentProgram) { return false; }

      try {
        const registerExistingParticipantResponse = await usersService.registerExistingParticipant(
          this.currentProgram.id,
          this.$route.params.userId,
          this.$route.params.token
        );

        if (registerExistingParticipantResponse) {
          this.$toast(makeSuccessToast("Email Verification successful. Please login now."));
        }

      } catch (e) {
        const { status, data } = e.response;
        if (status === 404 || status === 429 || status === 400 || status === 409) {
          this.$toast(makeErrorToast(data.message));
          if (status === 409) {
            const verifyParticipantEmailResponse = await usersService.verifyParticipantEmail(
              this.currentProgram.id,
              this.$route.params.userId,
              this.$route.params.token
            );

            if (verifyParticipantEmailResponse) {
              this.$toast(makeSuccessToast("Email Verification successful."));
            }
          }
        } else if (status === 422 && data.errors) {
          this.$refs.registerForm.setErrors(data.errors);
        } else {
          this.$toast(makeErrorToast(data.message));
          this.$log.error(e);
        }
      } finally {
        this.isLoading = false;
        this.$router.push({
          name: "participant-login",
        });
      }
    },
  }
};
</script>
<style lang="scss" scoped>
.spinner {
  width: 3em;
  height: 3em;
}
.card {
  max-width: 20em;
  margin: 10em auto 10em auto;
}
</style>
